import React from "react";
import shallow from "zustand/shallow";
import { StateMap } from "../../maps";
import { animated } from "react-spring";
import useStatesStore from "../useStatesStore";
import { Chip, withStyles } from "@material-ui/core";
import Stack from "../../Stack";
import GeoJsonLayer from "@hyperobjekt/svg-maps/lib/components/GeoJsonLayer";
import { countyShapeStyleObj, useCountyShapeStyles } from "../../maps/styles";
import { FiberManualRecord } from "@material-ui/icons";
import MapTooltip from "./ReleasesMapTooltip";
import { getStateCodeByName } from "../../../common/utils";

const { fill, stroke, fillOpacity, strokeOpacity } = countyShapeStyleObj;
const styles = (theme) => ({
  map: {
    "& path": {
      outline: "none",
    },
  },
  contentContainer: {
    margin: "auto",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      // justifyContent: "flex-end",
      flexDirection: "row",
      position: "absolute",
      bottom: theme.spacing(3),
      left: theme.spacing(1),
      right: 0,
    },
  },
  chip: {
    background: "none",
    "& svg": {
      fill: fill,
      stroke: stroke,
      fillOpacity: fillOpacity,
      strokeOpacity: strokeOpacity,
    },
  },
  legend: {
    margin: 0,
  },
  description: {
    maxWidth: "12.5rem",
    marginTop: "1rem",
    fontSize: theme.typography.pxToRem(12),
    opacity: 0.75,
  },
});

function getActiveCounties(data, stateName) {
  const { allJailReleases, allPrisonReleases } = data;

  const state_code = getStateCodeByName(stateName);
  const stateCode = state_code.toUpperCase();

  const counties = [...allPrisonReleases.edges, ...allJailReleases.edges].map(
    (edge) => edge.node.jurisdiction.toLowerCase()
  );

  const allCounties = require(`../../maps/StateMap/topojson/${stateCode}.json`);

  // clone
  const activeCounties = JSON.parse(JSON.stringify(allCounties));

  // Hacky way to access the sole property, of form "cb_2015_puerto_rico_county_20m"
  const keys = Object.keys(allCounties.objects);
  const countiesKey = keys[0];

  if (!countiesKey || !activeCounties.objects[countiesKey]) return [];
  activeCounties.objects[countiesKey].geometries = activeCounties.objects[
    countiesKey
  ].geometries.filter(
    (geo) =>
      geo.properties.NAME &&
      counties.includes(geo.properties.NAME.toLowerCase())
  );

  return activeCounties;
}


const ReleasesMap = ({ data, classes, content, stateName, ...props }) => {
  const [releasesFilter, setReleasesFilter] = useStatesStore(
    (state) => [state.releasesFilter, state.setReleasesFilter],
    shallow
  );

  const activeCounties = getActiveCounties(data, stateName);

  const shapeClasses = useCountyShapeStyles();
  const classMap = {
    shape: shapeClasses.shape,
    label: shapeClasses.shapeLabel,
    selected: shapeClasses.selected,
    text: shapeClasses.text,
  };
  const shapeProps = {
    classes: classMap,
    isSelectedMatch: (geo) => {
      return (
        releasesFilter &&
        geo.properties.NAME.toLowerCase() === releasesFilter.toLowerCase()
      );
    },
  };

  const onSelect = (geo) => {
    let toSelect = geo.properties.NAME;
    // unselect if user clicks the selected region
    if (toSelect === releasesFilter) {
      toSelect = null;
    }
    setReleasesFilter(toSelect);
  };

  return (
    <animated.div {...props}>
      <StateMap
        className={classes.map}
        height={800}
        width={700}
        stateName={stateName}
      >
        <GeoJsonLayer
          className="active-counties"
          // classes={classMap}
          source={activeCounties}
          shapeProps={shapeProps}
          onSelect={onSelect}
          interactive
        />
      </StateMap>
      <MapTooltip />
      <Stack className={classes.contentContainer} horizontal="md" spacing={3}>
        <Chip
          classes={{ root: classes.chip }}
          icon={<FiberManualRecord />}
          label={content.releases.description}
          onDelete={null}
        />
      </Stack>
    </animated.div>
  );
};

ReleasesMap.propTypes = {};

export default withStyles(styles)(ReleasesMap);
