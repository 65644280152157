import React from "react";
import { graphql } from "gatsby";
import Layout from "gatsby-theme-hypersite/src/layout";
import { makeStyles, Typography } from "@material-ui/core";
import {
  Facilities,
  Scorecard,
  ReleasesTable,
  GrassrootsTable,
  StateSummary,
} from "../components/states/sections";
import useStatesStore from "../components/states/useStatesStore";
import shallow from "zustand/shallow";
import SectionNavigation from "../components/SectionNavigation";
import content from "../../content/lang/states.json";
import { Block } from "@hyperobjekt/material-ui-website";
import ReleasesMap from "../components/states/visuals/ReleasesMap";
import { FacilitiesMap } from "../components/states/visuals";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
  block: {
    alignItems: "flex-start",

    // make room for legend
    "&#facilities .rsm-svg": {
      flex: 1,
      maxHeight: `calc(100% - 5rem)`,
      [theme.breakpoints.up("md")]: {
        transform: `none`,
      },
    },
  },
  dualColumn: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },

    "& > *": {
      // left column:
      "&:nth-child(1)": {
        // flex to keep better aligned with right column
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: 0,
        width: "100%",
        // zIndex: 1,
        [theme.breakpoints.up("md")]: {
          width: "auto",
          minHeight: `calc(100vh - ${theme.layout.headerHeight})`,
        },
      },

      // right column:
      "&:nth-child(2)": {
        // full vertical height, minus header
        height: `auto`,
        minHeight: 420,
        // span full viewport width
        width: `100%`,
        margin: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "stretch",
        // zIndex: 1,
        // overflow: "hidden",
        [theme.breakpoints.up("sm")]: {
          maxHeight: `calc(100vh - ${theme.layout.headerHeight})`,
        },
        [theme.breakpoints.up("md")]: {
          height: `calc(100vh - ${theme.layout.headerHeight} - 56px)`,
          marginRight: theme.spacing(-4),
          position: "sticky",
          top: theme.layout.headerHeight,
        },
        [theme.breakpoints.up("lg")]: {
          marginRight: "unset",
        },

        position: "relative",
      },
    },
  },

  visual: {
    position: "absolute",
    height: "100%",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    margin: 0,
    // mapVisual
    padding: 0,
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      paddingLeft: theme.spacing(3),
    },
  },
}));

const StateTemplate = ({ pageContext, data }) => {
  // classes used on this page
  const classes = useStyles();

  // pull state name from page context
  const { state } = pageContext;

  const [stateName, setStateName, setReleasesFilter] = useStatesStore(
    (state) => [state.stateName, state.setStateName, state.setReleasesFilter],
    shallow
  );
  if (state !== stateName) {
    setReleasesFilter(null);
    setStateName(state);
  }

  const [currentSection, setCurrentSection] = React.useState("aggregates");

  const scorecardData = data.scorecard?.nodes[0];
  // setctions for section nav
  const sections = [
    content["aggregates"],
    content["facilities"],
    content["scorecard"],
    content["releases"],
    content["grassroots"],
  ]
    .map(({ id, link }) => ({
      id,
      name: link,
    }))
    .filter((section) => scorecardData || section.id !== "scorecard");

  /** Set the current section when visibility changes */
  const handleVisibilityChange = (isVisible, e) => {
    // ignore if not visible
    if (!isVisible || !e || !e.target) return;
    const sectionId = e.target.id;
    setCurrentSection(sectionId);
  };

  // common props among all blocks
  const BlockProps = {
    className: classes.block,
    IntersectionOptions: { threshold: 0.5 },
    onVisibleChange: handleVisibilityChange,
  };
  return (
    <Layout title={state}>
      <SectionNavigation current={currentSection} sections={sections} />
      <Block id="aggregates" {...BlockProps}>
        <Typography variant="h2" className={classes.title}>
          {state}
        </Typography>
        <StateSummary
          data={data}
          scorecardLang={content.scorecard}
          aggregatesLang={content.aggregates}
          state={state}
        />
      </Block>
      <Block
        id="facilities"
        bgcolor="background.paper"
        classes={{ container: classes.dualColumn }}
        {...BlockProps}
      >
        <Facilities
          data={data}
          className={classes.leftColumn}
          lang={content.facilities}
          stateName={state}
        />
        <FacilitiesMap
          className={classes.visual}
          content={content}
          stateName={state}
        />
      </Block>
      <Block id="scorecard" {...BlockProps}>
        <Scorecard data={data} lang={content.scorecard} state={state} />
      </Block>
      <Block
        id="releases"
        bgcolor="background.paper"
        {...BlockProps}
        classes={{ container: classes.dualColumn }}
      >
        <ReleasesTable data={data} lang={content.releases} state={state} />
        <ReleasesMap
          data={data}
          className={classes.visual}
          content={content}
          stateName={state}
        />
      </Block>
      <Block id="grassroots" {...BlockProps}>
        <GrassrootsTable data={data} lang={content.grassroots} state={state} />
      </Block>
    </Layout>
  );
};

StateTemplate.propTypes = {};

export const query = graphql`
  query ($state: String!) {
    allAggregates(filter: { state: { eq: $state } }) {
      edges {
        node {
          jurisdiction
          measure
          rate
          val
        }
      }
    }
    allFacilities(filter: { state: { eq: $state } }) {
      edges {
        node {
          id
          name
          coords
          city
          jurisdiction
          residents {
            active
            active_rate
            confirmed
            confirmed_rate
            deaths
            deaths_rate
            tested
            tested_rate
            population
          }
          staff {
            active
            confirmed
            deaths
          }
          date
        }
      }
    }
    allFilings(filter: { state: { eq: $state } }) {
      edges {
        node {
          courtCount
          facilityCount
          granted
          total
        }
      }
    }
    scorecard: allScorecard(filter: { state: { eq: $state } }) {
      nodes {
        score
        date
        machine
        regularly
        history
        defined
        cases_residents
        deaths_residents
        active_residents
        tests_residents
        population_residents
        vaccinations_residents
        booster_residents
        cases_staff
        deaths_staff
        tests_staff
        vaccinations_staff
        active_staff
        population_staff
        booster_staff
      }
    }
    fedScorecard: allScorecard(filter: { state: { eq: "Federal (BOP)" } }) {
      nodes {
        score
      }
    }
    iceScorecard: allScorecard(filter: { state: { eq: "Immigration (ICE)" } }) {
      nodes {
        score
      }
    }
    allGrassroots(filter: { state: { eq: $state } }) {
      edges {
        node {
          county
          date
          external_effort
          facility
          internal_effort
          organization
          releases
          response
          sanitary
          source
          success
          testing
          type
        }
      }
    }
    allJailReleases(filter: { state: { eq: $state } }) {
      edges {
        node {
          facility
          capacity
          releases
          population
          source
          jurisdiction
          date
          authority
          detailParole
          detailMinor
          detailBail
          detailShort
          detailVulnerable
          detailOther
        }
      }
    }
    allPrisonReleases(filter: { state: { eq: $state } }) {
      edges {
        node {
          facility
          capacity
          releases
          population
          source
          jurisdiction
          date
          authority
          detailParole
          detailMinor
          detailShort
          detailVulnerable
          detailOther
        }
      }
    }
  }
`;

export default StateTemplate;
