import useMapStore from "@hyperobjekt/svg-maps/lib/hooks/useMapStore";
import React from "react";
import Tooltip from "../../Tooltip";

const MapTooltip = () => {
  const hovered = useMapStore((state) => state.hovered);
  if (!hovered || !hovered.properties.NAME) return null;
  return <Tooltip>{hovered.properties.NAME}</Tooltip>;
};

MapTooltip.propTypes = {};

export default MapTooltip;
